.App {
  /* text-align: center; */
  padding: 4rem;
}
body{
  background-color: #eeeeee;
}
.error{
  color: red;
 
}
.textfield-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.error {
  color: red;
  margin-top: 4px;
  font-size: 12px;
}
